<template>
  <div v-if="notes">
    <div class="flix-form-group">
      <h3 class="flix-html-h3">{{ $tc("message.note", Object.values(notes).length) }}</h3>
    </div>
    <div class="flix-form-group">
      <div v-html="Object.values(notes)[0].msg"></div>
    </div>

    <notesList :key="key" :notes="notes"/>
    <newNote :callback="function(note){setNote(note)}"/>

  </div>
</template>

<script>
export default {
  props: { entry: Object },
  components: {
    notesList () { return import('@/components/notes/list') },
    newNote () { return import('@/components/notes/new') }
  },
  data () {
    return {
      notes: false,
      key: new Date().getTime()
    }
  },
  methods: {
    setNote (note) {
      this.notes[new Date().getTime()] = note
      this.key = new Date().getTime()

      this.$flix_post({
        data: {
          user: this.$store.getters.user.md5_id,
          form: this.entry.form,
          entryID: this.entry.md5ID,
          msg: note.msg
        },
        url: 'notes/set',
        callback: function (ret) { window.console.log(ret) }
      })
    }
  },
  computed: {
  },
  mounted () {
    this.$flix_post({
      data: {
        form: this.entry.form,
        entryID: this.entry.md5ID,
        user: this.$store.getters.user.md5_id
      },
      url: 'notes/get',
      callback: function (ret) {
        if (ret.data[0]) {
          this.notes = ret.data[1]
        }
      }.bind(this)
    })
  }
}
</script>
